import React from 'react';
import {Route, Routes} from 'react-router-dom';

import HomePage from './pages/homePage/homePage';
import Header from "./components/header/header";
import {SignUpPage} from "./pages/userJourneyPages/signUpPage";
import {LoginPage} from "./pages/userJourneyPages/loginPage";
import {ResetPasswordPage} from "./pages/userJourneyPages/resetPasswordPage";
import {NewPasswordPage} from "./pages/userJourneyPages/newPasswordPage";
import {Footer} from "../shared/components/footer/footer";
import {ResetPasswordSuccessPage} from "./pages/userJourneyPages/resetPasswordSuccessPage";
import {NotFoundPage} from "../shared/pages/notFoundPage/notFoundPage";

function Landing() {
    const styles = {
        landingContainer: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'space-between'
        }
    }

    return (
        <div style={styles.landingContainer}>
            <Header/>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="payment-processing" element={<SignUpPage/>}/>
                <Route path="login" element={<LoginPage/>}/>
                <Route path="reset-password" element={<ResetPasswordPage/>}/>
                <Route path="new-password" element={<NewPasswordPage/>}/>
                <Route path="password-changed" element={<ResetPasswordSuccessPage/>}/>
                <Route path="*" element={<NotFoundPage/>}/>
            </Routes>
            <Footer/>
        </div>
    );
}

export default Landing;
