import styles from "./footer.module.scss";
import TelegramIcon from "../../../assets/images/icons/telegram.svg";
import YouTubeIcon from "../../../assets/images/icons/youtube.svg";
import InstagramIcon from "../../../assets/images/icons/instagram.svg";
import React from "react";

export const Footer = () => {
    return (
        <div className={`${styles.footer} container`}>
            <div className={`${styles.footerContainer}`}>
                <div className={styles.socialMedia}>
                    <span className={`${styles.feedback} raleway-16-regular`}>Зворотній зв'язок</span>
                    <a href="https://t.me/magildaa" target="_blank" rel="noopener noreferrer">
                        <img src={TelegramIcon} alt="TelegramIcon"/>
                    </a>
                    <a href="https://www.youtube.com/@magilda" target="_blank" rel="noopener noreferrer">
                        <img src={YouTubeIcon} alt="YouTubeIcon"/>
                    </a>
                    <a href="https://www.instagram.com/magilda" target="_blank" rel="noopener noreferrer">
                        <img src={InstagramIcon} alt="InstagramIcon"/>
                    </a>
                </div>
                <div className={styles.policy}>
                    <p className={`${styles.policy__text} raleway-16-regular`}>*політика повернення коштів відсутня</p>
                    <p className={`${styles.policy__text} raleway-16-regular`}>Copyright © 2023</p>
                </div>
            </div>
        </div>
    )
}
