import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Checkbox from "../../../shared/components/checkbox/checkbox";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useTariffs, buyTariffs, createSignature } from "shared/services/TariffContext";
import { useAuth } from "../../../shared/services/AuthContext";
import styles from "./userJourney.module.scss";
import WayForPayForm from 'shared/components/wayForPayForm/wayForPayForm';
import RadioButton from "shared/components/radioButton/radioButton";

export const SignUpPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        selectedTariffId: null,
        publicOffer: false,
        personalData: false,
    });
    const [paymentData, setPaymentData] = useState(null);
    const { allTariffs } = useTariffs();
    const { signUp } = useAuth();
    const goBack = useNavigation();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tariffId = queryParams.get("tariffId");
        if (tariffId) {
            setFormData((prevState) => ({
                ...prevState,
                selectedTariffId: tariffId,
            }));
        }
    }, [location]);

    const handleInputChange = ({ target: { name, value } }) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleRadioChange = (tariffId) => {
        setFormData((prevState) => ({
            ...prevState,
            selectedTariffId: tariffId,
        }));
    };

    const handleSitePolicyChange = (key, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { publicOffer, personalData, password, confirmPassword, selectedTariffId } = formData;
        if (publicOffer && personalData && password === confirmPassword && selectedTariffId) {
            try {
                const user = await signUp(formData);
                const orderReferences = await buyTariffs(user, [selectedTariffId]); // Передаємо масив з одним тарифом
                const signatureResult = await createSignature(user, orderReferences);
                localStorage.setItem('user', JSON.stringify(user));
                setPaymentData(signatureResult);
            } catch (error) {
                console.error("There was an error!", error);
            }
        }
    };

    // Обчислення вартості вибраного тарифу
    const totalCost = React.useMemo(() => {
        const tariff = allTariffs.find((t) => t.id === formData.selectedTariffId);
        return tariff ? tariff.price : 0;
    }, [formData.selectedTariffId, allTariffs]);

    return (
        <div className={`${styles.userJourney} container`}>
            <div onClick={goBack} className={styles.userJourney__back}>Повернутись</div>
            <div className={styles.userJourney__content}>
                <UserJourneyInfo />
                <UserJourneyForm
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    totalCost={totalCost}
                    allTariffs={allTariffs}
                    handleRadioChange={handleRadioChange}
                    handleSitePolicyChange={handleSitePolicyChange}
                />
                {paymentData && <WayForPayForm data={paymentData} />}
            </div>
        </div>
    );
};

const UserJourneyInfo = () => (
    <div className={styles.infoText}>
        <h2 className={`${styles.infoText__h2} montserrat-36-black`}>
            Оформлення тарифу
        </h2>
        <p className={`${styles.infoText__p} raleway-16-regular`}>
            Заповни форму, щоб зареєструватись та придбати тариф або{" "}
            <Link className={`${styles.link} raleway-16-bold`} to="/landing/login">
                авторизуйся
            </Link>, якщо ти вже в команді
        </p>
    </div>
);

const UserJourneyForm = ({
                             formData,
                             handleInputChange,
                             handleSubmit,
                             totalCost,
                             allTariffs,
                             handleRadioChange,
                             handleSitePolicyChange,
                         }) => (
    <form className={`${styles.formContainer} form`} onSubmit={handleSubmit}>
        <InputField name="name" placeholder="Твоє ім'я*" value={formData.name} onChange={handleInputChange} required />
        <InputField name="email" placeholder="Email*" autoComplete="email" type="email" value={formData.email} onChange={handleInputChange} required />
        <InputField name="phone" placeholder="Телефон*" autoComplete="tel" type="tel" value={formData.phone} onChange={handleInputChange} required />
        <InputField name="password" placeholder="Пароль*" type="password" value={formData.password} onChange={handleInputChange} required />
        <InputField name="confirmPassword" placeholder="Повтор паролю*" type="password" value={formData.confirmPassword} onChange={handleInputChange} required />
        <TariffsSelection
            allTariffs={allTariffs}
            selectedTariffId={formData.selectedTariffId}
            onChange={handleRadioChange}
        />
        <span className={`${styles.totalCost} raleway-24-bold`}>{totalCost} грн</span>
        <div className={styles.sitePolicy}>
            <div className={styles.sitePolicy__checkbox}>
                <Checkbox
                    label="Я погоджуюсь з умовами “Політики обробки персональних данних”"
                    defaultChecked={formData.personalData}
                    onChange={(isChecked) => handleSitePolicyChange('personalData', isChecked)}
                    isLabelOpaque={true}
                />
            </div>
            <div className={styles.sitePolicy__checkbox}>
                <Checkbox
                    label="Я приймаю умови “Публічна оферта”"
                    defaultChecked={formData.publicOffer}
                    onChange={(isChecked) => handleSitePolicyChange('publicOffer', isChecked)}
                    isLabelOpaque={true}
                />
            </div>
        </div>
        <button className={`${styles.formContainer__btn} btn-brown`} type="submit">
            Перейти до оплати
        </button>
    </form>
);

const InputField = ({ name, placeholder, autoComplete = "off", type = "text", value, onChange, required }) => (
    <input className={"input"} type={type} name={name} placeholder={placeholder} autoComplete={autoComplete} value={value} onChange={onChange} required={required} />
);

const TariffsSelection = ({ allTariffs, selectedTariffId, onChange }) => (
    <div className={styles.formContainer__chooseTariff}>
        {allTariffs && allTariffs.map((tariff) => (
            <div key={tariff.id} className={styles.formContainer__chooseTariff__checkbox}>
                <RadioButton
                    label={tariff.title}
                    name="tariff"
                    value={tariff.id}
                    checked={selectedTariffId === tariff.id}
                    onChange={onChange}
                />
            </div>
        ))}
    </div>
);

export default SignUpPage;
