import Modal from "../modal/modal";
import React from "react";
import styles from './modalCancelTariff.module.scss'

export const ModalCancelTariff = ({onBtnClickNo, onBtnClickYes}) => {
    return (
        <Modal>
            <div className="modal">
                <div className={styles.modalContent}>
                    <h2 className={`${styles.title} raleway-24-bold`}>Ти дійсно хочеш скасувати тариф?</h2>
                    <p className={`${styles.description} raleway-16-regular`}>Будь ласка, зверни увагу, що при
                        скасуванні підписки кошти не повертаються. Тобі буде надано доступ до матеріалу до завершення
                        терміну.</p>
                    <div>
                        <button className={`${styles.btn} btn-transparent`} onClick={onBtnClickNo}>Ні, залишити</button>
                        <button className={`${styles.btn} btn-brown`} onClick={onBtnClickYes}>Так, скасувати</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
