import React, { useState } from "react";
import styles from "./collapsibleQuestionOftenAsked.module.scss";

const CollapsibleQuestionOftenAsked = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.content}>
      <h4
        className={`${styles.questionTitle} raleway-24-regular`}
        onClick={toggle}
      >
        {title}
        <span
          className={`${styles.toggle} ${isOpen ? `${styles.active}` : ""}`}
        />
      </h4>
      {isOpen && (
        <div className={`${styles.answerText} raleway-16-regular`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default function CollapsibleQuestionOftenAskedSection() {
  return (
    <div>
      <CollapsibleQuestionOftenAsked title="Кому підійде матеріал?">
        <p>
          Це універсальна програма, яка в першу чергу направлена на покращення
          самопочуття та здоров’я. Займатись можуть, як діти, так і дорослі
          люди, також жінки і чоловіки, хоча 90% участь беруть саме жінки. Окрім
          здоров’я, програма націлена щоб допомогти покращити поставу, позбутись
          болів в тілі, схуднути, стати гнучким, сісти на шпагат, почати
          правильно збалансовано харчуватись, відчути себе сильним, енергійним і
          щасливим.
        </p>
      </CollapsibleQuestionOftenAsked>
      <CollapsibleQuestionOftenAsked title="Які є протипоказання?">
        <ul
          style={{
            paddingLeft: "18px",
          }}
        >
          <li>
            Вагітність (або з дозволу вашого лікаря);
            <br />
          </li>
          <li>
            Перші три місяці після пологів, кесаревого розтину чи інших
            операцій;
            <br />
          </li>
          <li>
            Стан після перенесення інсульту, інфаркту;
            <br />
          </li>
          <li>
            Порушення згортання крові, тромбофлебіт, тромбоз;
            <br />
          </li>
          <li>
            Хронічні хвороби в період загострення, особливо протрузії та грижі;
            <br />
          </li>
          <li>
            Остеопенія/остеопороз;
            <br />
          </li>
          <li>
            Наявність ендопротезів;
            <br />
          </li>
          <li>
            Органічні ураження серця (вади серця);
            <br />
          </li>
          <li>
            Епілепсія;
            <br />
          </li>
          <li>
            Онкологічні патології;
            <br />
          </li>
          <li>
            Нещодавно перенесені запалення і операції;
            <br />
          </li>
          <li>
            Переломи, вивихи, розриви сухожиль і м'язів до моменту відновлення
            (зрощування);
            <br />
          </li>
          <li>
            Крововиливи (внутрішні та зовнішні);
            <br />
          </li>
          <li>
            Тромбофлебіт;
            <br />
          </li>
          <li>
            Гострий предінфарктний і предінсультний стан;
            <br />
          </li>
          <li>
            Важка аритмія;
            <br />
          </li>
          <li>
            Висока температура тіла;
            <br />
          </li>
          <li>
            Гіпертонічний криз;
            <br />
          </li>
          <li>
            Хронічні захворювання в стадії загострення.
            <br />
          </li>
        </ul>
      </CollapsibleQuestionOftenAsked>
      <CollapsibleQuestionOftenAsked title="Скільки часу доступу буде до матеріалів після оплати?">
        <p>
          28 днів з моменту оплати. Після завершення доступ закривається, щоб
          його продовжити - потрібно оплатити суму за тариф.
        </p>
      </CollapsibleQuestionOftenAsked>
      <CollapsibleQuestionOftenAsked title="Який потрібен інвентар?">
        <p>
          Після оплати на платформі на тебе чекатиме відео-інструкція, де я все
          тобі покажу та поділюсь чим можна замінити з домашніх речей, якщо
          немає можливості придбати.
        </p>
      </CollapsibleQuestionOftenAsked>
      <CollapsibleQuestionOftenAsked title="Де шукати усю інформацію після оплати?">
        <p>
          Наша платіжна система WayForPay, підтримує будь яку карту, тому можете
          сміливо оплачувати та не хвилюватись.
        </p>
      </CollapsibleQuestionOftenAsked>
    </div>
  );
}
