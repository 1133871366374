import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config";

const ContentContext = createContext();

export function useContent() {
  return useContext(ContentContext);
}

export const ContentProvider = ({ children }) => {
  const [contentGroups, setContentGroups] = useState([]);
  const [detailsContentGroups, setDetailsContentGroups] = useState([]);

  useEffect(() => {
    getContentGroups();
  }, []);

  const getContentGroups = () => {
    return axios
      .get(`${API_URL}/pages/getContentGroups`)
      .then((response) => {
        const contentGroupsData = response.data.data;

        if (contentGroupsData) {
          setContentGroups(contentGroupsData);
          contentGroupsData.forEach((contentGroup) => getDetailsContentGroup(contentGroup.id))
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const getDetailsContentGroup = async (contentGroupId) => {
    try {
      const response = await axios.get(
          `${API_URL}/pages/getDetailsContentGroup`,
          {
            params: { groupId: contentGroupId }
          }
      );

      setDetailsContentGroups(prevGroups => [...prevGroups, response.data.data]);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const getAssetDetails = async (assetId) => {
    try {
      const response = await axios.get(
          `${API_URL}/pages/getAssetDetails`,
          {
            params: { id: assetId }
          }
      );

      return response.data.data;
    } catch (error) {
      console.error("Error", error);
    }
  };

  const uploadPicture = async (assetId) => {
    try {
      const response = await axios.post(`${API_URL}/pages/uploadPicture`, {
        id: assetId,
      });

      return response.data.data;
    } catch (error) {
      console.error("Error", error);
    }
  };

  const value = {
    contentGroups,
    detailsContentGroups,
    getAssetDetails,
    uploadPicture,
  };

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  );
};
