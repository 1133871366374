import { useState, useEffect } from "react";
import styles from './checkbox.module.scss';

const Checkbox = ({ label, defaultChecked = false, onChange, isLabelOpaque = false }) => {
    const [isChecked, setIsChecked] = useState(defaultChecked);

    const handleChange = () => {
        setIsChecked((prev) => !prev);
        onChange(!isChecked);
    };

    useEffect(() => {
        setIsChecked(defaultChecked);
    }, [defaultChecked]);

    const labelStyle = isLabelOpaque ? { opacity: '40%' } : {};

    return (
        <label className={`${styles.checkboxContainer} raleway-16-regular`}>
            <span style={labelStyle}>{label}</span>
            <input type="checkbox"
                   className={styles.checkbox}
                   checked={isChecked}
                   onChange={handleChange}
            />
            <span className={styles.checkmark} />
        </label>
    );
};
export default Checkbox;

