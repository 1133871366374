import React, {useState} from 'react';
import styles from './header.module.scss'
import logoImage from '../../../assets/images/icons/logo.svg';
import {Link, useNavigate} from "react-router-dom";
import {Footer} from "../../../shared/components/footer/footer";

const Header = () => {
    const navigate = useNavigate();
    const [isBurgerActive, setIsBurgerActive] = useState(false);

    const toggleClass = () => {
        setIsBurgerActive(!isBurgerActive);
    };

    const scrollToSection = (sectionId) => {
        if (window.location.pathname !== '/landing') {
            navigate('/landing');
        }
        setTimeout(() => {
            const section = document.querySelector(sectionId);
            if (section) {
                section.scrollIntoView({behavior: 'smooth'});
            }
        }, 100);
    };

    return (
        <header className={`container ${styles.header} ${isBurgerActive ? styles.header_active : ''}`}>
            <Link to="/" className={styles.logoContainer}>
                <img className={styles.logo} src={logoImage} alt="logo"/>
            </Link>
            <nav role="navigation" className={styles.nav}>
                <a className={`${styles.nav__item} raleway-16-regular`} onClick={() => scrollToSection('#aboutMe')}>Про
                    мене</a>
                <a className={`${styles.nav__item} raleway-16-regular`}
                   onClick={() => scrollToSection("#motivation")}>Мотивація</a>
                <a className={`${styles.nav__item} raleway-16-regular`}
                   onClick={() => scrollToSection("#feedback")}>Відгуки</a>
                <a className={`${styles.nav__item} raleway-16-regular`}
                   onClick={() => scrollToSection("#tariffs")}>Тарифи</a>
                <a className={`${styles.nav__item} raleway-16-regular`}
                   onClick={() => scrollToSection("#popularQuestions")}>Питання та
                    відповіді</a>
                <Link to="/landing/payment-processing">
                    <button className="btn-brown" onClick={isBurgerActive ? toggleClass : undefined}>Вхід
                    </button>
                </Link>
                <div className={`${styles.menuButton} ${isBurgerActive ? `${styles.active}` : ''}`}
                     onClick={toggleClass}>
                    <div className={styles.menuButtonBurger}/>
                </div>
            </nav>
            <div className={`${styles.navMobile} ${isBurgerActive ? `${styles.active}` : ''}`}>
                <div className={styles.navLinks}>
                    <a className={`${styles.navLinks__item} raleway-24-regular`}
                       onClick={() => {
                        toggleClass();
                        scrollToSection('#aboutMe')
                    }}>Про
                        мене</a>
                    <a className={`${styles.navLinks__item} raleway-24-regular`}
                       onClick={() => {
                           toggleClass();
                           scrollToSection('#motivation')
                       }}>Мотивація</a>
                    <a className={`${styles.navLinks__item} raleway-24-regular`}
                       onClick={() => {
                           toggleClass();
                           scrollToSection('#feedback')
                       }}>Відгуки</a>
                    <a className={`${styles.navLinks__item} raleway-24-regular`}
                       onClick={() => {
                           toggleClass();
                           scrollToSection('#tariffs')
                       }}>Тарифи</a>
                    <a className={`${styles.navLinks__item} raleway-24-regular`}
                       onClick={() => {
                           toggleClass();
                           scrollToSection('#popularQuestions')
                       }}>Питання та відповіді</a>
                </div>
                <div className={styles.footer}>
                    <Footer/>
                </div>
            </div>
        </header>
    );
};

export default Header;
