import Modal from "../modal/modal";
import React from "react";
import modalSuccessfulIcon from '../../images/icons/modal-successful.svg'
import styles from './modalSuccessful.module.scss'

export const ModalSuccessful = ({ description, onBtnClick }) => {
    return (
        <Modal>
            <div className="modal">
                <div className={styles.modalSuccessful}>
                    <img className={`${styles.img}`} src={modalSuccessfulIcon} alt="modalSuccessfulIcon"/>
                    <h2 className={`${styles.title} raleway-24-bold`}>Операція успішна!</h2>
                    <p className={`${styles.description} raleway-16-regular`}>{description}</p>
                    <button className={`${styles.btn} btn-brown`} onClick={onBtnClick}>Повернутись до платформи</button>
                </div>
            </div>
        </Modal>
    )
}
