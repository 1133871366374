import styles from './poster.module.scss'
import stubOnPoster from '../../images/photos/stub-on-video.jpg'
import {Link} from "react-router-dom";

export const Poster = (props) => {
    return (
        <Link style={{
            textDecoration: 'none',
            color: 'black'
        }} to={`/platform/poster-details/${props.poster.id}`}>
            <div className={styles.poster}>
                <img className={styles.poster__img} src={props.poster.imageUrl} alt="stubOnPoster"/>
                <h3 className={`${styles.poster__title} raleway-16-bold`}>{props.poster.name}</h3>
                <p className={`${styles.poster__duration} raleway-16-regular`}>{props.poster.duration} хв</p>
            </div>
        </Link>
    )
}
