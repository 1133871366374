import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { API_URL } from "../../config";
import axios from "axios";

export const buyTariffs = async (user, selectedTariffsId) => {
  try {
    const response = await axios.post(`${API_URL}/tariff/purchaseTariff`, {
      tariffIds: selectedTariffsId,
    }, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    });
    return response.data.data.orderReferences;
  } catch (error) {
    console.error("There was an error!", error);
    throw error;
  }
};

export const createSignature = async (user, orderReference) => {
  try {
    const response = await axios.post(`${API_URL}/tariff/createSignature`, {
      orderReference,
    }, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("There was an error!", error);
    throw error;
  }
};

const TariffContext = createContext();

export const useTariffs = () => {
  return useContext(TariffContext);
};

export const TariffProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [allTariffs, setAllTariffs] = useState([]);
  const [availableTariffs, setAvailableTariffs] = useState([]);
  const [connectedTariffs, setConnectedTariffs] = useState([]);

  useEffect(() => {
    getAllTariffs().then((data) => setAllTariffs(data));
  }, []);

  const getAllTariffs = () => {
    return axios
      .get(`${API_URL}/tariff/getList`)
      .then((response) => {
        return response.data.data.items;
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const getAvailableTariff = () => {
    return axios
      .get(`${API_URL}/tariff/getAvailableTariffs`)
      .then((response) => {
        setAvailableTariffs(response.data.data.items);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const getConnectedTariffs = () => {
    return axios
      .get(`${API_URL}/tariff/getConnectedTariffs`)
      .then((response) => {
        // setConnectedTariffs(response.data.data.items);

        setConnectedTariffs(() => { // map data need to remove 
         return response.data.data.items.map((item) => ({...item, active: true}))
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const resumeAutoPayment = (orderReference) => {
    return axios
      .post(`${API_URL}/tariff/resumeAutoPayment`, {
        orderReference: orderReference,
      })
      .then(() => {
        getConnectedTariffs();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const cancelAutoPayment = (orderReference) => {
    return axios
      .post(`${API_URL}/tariff/cancelAutoPayment`, {
        orderReference: orderReference,
      })
      .then(() => {
        getConnectedTariffs();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };


  const getPaymentHistory = () => {
    return axios
        .post(`${API_URL}/tariff/getPaymentHistory`)
        .then((response) => {
          return response.data.data
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
  };

  const addTariffToCart = useCallback(
    (tariffId) => {
      setCart((cart) => {
        const tariff =
          allTariffs && allTariffs.find((t) => t.id === tariffId);
        const isAlreadyInCart = cart.some((item) => item.id === tariffId);
        if (tariff && !isAlreadyInCart) {
          return [...cart, tariff];
        }
        return cart;
      });
    },
    [allTariffs]
  );

  const removeTariffFromCart = useCallback(
    (tariffId) => {
      setCart((cart) => cart.filter((t) => t.id !== tariffId));
    },
    [cart]
  );

  return (
    <TariffContext.Provider
      value={{ cart, allTariffs, addTariffToCart, removeTariffFromCart, getAvailableTariff, availableTariffs, getConnectedTariffs, connectedTariffs, resumeAutoPayment, cancelAutoPayment, getPaymentHistory }}
    >
      {children}
    </TariffContext.Provider>
  );
};
