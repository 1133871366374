import { Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";

import { Footer } from "../shared/components/footer/footer";
import { HomePage } from "./pages/homePage/homePage";
import { NotFoundPage } from "../shared/pages/notFoundPage/notFoundPage";
import Header from "./components/header/header";
import { SettingsPage } from "./pages/settingsPage/settingsPage";
import { ContentGroupPage } from "./pages/contentGroupPage/contentGroupPage";
import { PosterDetailsPage } from "./pages/posterDetailsPage/posterDetailsPage";
import { useTariffs } from "shared/services/TariffContext";
import { ContentProvider } from "shared/services/ContentContext";

function PortalComponent() {
  const styles = {
    platformContainer: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      justifyContent: "space-between",
      background: "#F2F2F2",
    },
  };

  const { getConnectedTariffs } = useTariffs();

  useEffect(() => {
    getConnectedTariffs();
  }, []);

  return (
    <div style={styles.platformContainer}>
      <ContentProvider>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/content-group/:id" element={<ContentGroupPage />} />
          <Route path="/poster-details/:id" element={<PosterDetailsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </ContentProvider>
    </div>
  );
}

export default PortalComponent;
