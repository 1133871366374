import React, {useEffect, useState} from 'react';
import AvatarStub from '../../images/icons/avatar-stub.svg'
import styles from './profilePhotoChanger.module.scss'
import {API_URL} from "../../../config";

const ProfilePhotoChanger = ({user, onPhotoChange}) => {
    const [photo, setPhoto] = useState(AvatarStub);

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto(reader.result);
            onPhotoChange(file)
        };

        reader.readAsDataURL(file);
    };

    useEffect(() => {
        if (user.imagePath) {
            setPhoto(`${API_URL}/${user.imagePath}`)
        }
    }, []);

    return (
        <div>
            <div className={styles.img} style={{backgroundImage: `url(${photo})`}}
                 onClick={() => document.querySelector('input[type="file"]').click()}
            >
                <input type="file" onChange={handlePhotoChange} style={{visibility: 'hidden'}}/>
            </div>
        </div>
    );
};


export default ProfilePhotoChanger;
