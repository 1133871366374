import styles from './posterList.module.scss'
import {Poster} from "../poster/poster";

export const PostersList = (props) => {
    let assets;
    if (props.contentGroup?.assets?.length) {
        assets = props.contentGroup.assets;
    }

    return (
        <div className={styles.postersList}>
            {assets?.slice(0, props.maxLength).map(poster => <Poster key={poster.id} poster={poster}/>)}
        </div>
    )
}
