import styles from './contentGroupPage.module.scss'
import {PostersList} from "../../components/postersList/postersList";
import {Link, useParams} from "react-router-dom";
import { useContent } from 'shared/services/ContentContext';

function findContentGroupById(groups, id) {
    for (const group of groups) {
        if (group.id === id) {
            return group;
        }
        if (group.folders && group.folders.length > 0) {
            const found = findContentGroupById(group.folders, id);
            if (found) {
                return found;
            }
        }
    }
    return undefined;
}

export const ContentGroupPage = () => {
    const { id } = useParams();
    const { detailsContentGroups } = useContent()
    const contentGroup = findContentGroupById(detailsContentGroups, id)

    if (contentGroup?.hasFolder) {
        const assets = contentGroup.folders.map(groupFromFolder => groupFromFolder.assets)
            .reduce((acc, current) => acc.concat(current), []);
        const combineTitle = contentGroup.folders.filter(groupFromFolder => groupFromFolder.assets.length).map(groupFromFolder => groupFromFolder.name).join(', ')
        contentGroup.assets = assets;
        contentGroup.name = combineTitle;
    }

    return (
        <div className={`${styles.contentGroupPage} container`}>
            <Link className={styles.contentGroupPage__link} to="/platform">
                <h2 className={`${styles.contentGroupPage__title} raleway-24-bold`}>
                    {contentGroup?.name}
                </h2>
            </Link>

            <div className={styles.contentGroupPage__postersList}>
                <PostersList contentGroup={contentGroup} maxLength={contentGroup?.assets?.length} />
            </div>
        </div>
    )
}
