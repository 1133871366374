import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { API_URL } from "../../config";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const login = async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/user/login`, {
        email: formData.email,
        password: formData.password,
      });

      const user = response.data.data;
      localStorage.setItem('user', JSON.stringify(user));
      setUser(user)
    } catch (error) {
      console.error("Error", error);
    }
  };

  const signUp = async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/user/signUp`, {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        password: formData.password,
      });

      const user = response.data.data;
      return user;
    } catch (error) {
      if (error?.response?.data?.message === 'USER_ALREADY_EXIST') {
        login(formData)
      }
      console.error("Error", error);
    }
  };

  const changeUserInfo = async (formData) => {
    const data = new FormData();

    if (formData.avatar) {
      data.append('file', formData.avatar);
    }

    if (formData.name) {
      data.append('name', formData.name);
    }

    if (formData.phone) {
      data.append('phone', formData.phone);
    }

    if (formData.email) {
      data.append('email', formData.email);
    }

    if (formData.birthday) {
      data.append('birthday', formData.birthday);
    }

    try {
      const response = await axios.post(`${API_URL}/user/changeUserInfo`, data, {
        'Content-Type': 'multipart/form-data',
      });

      const user = response.data.data;
      localStorage.setItem('user', JSON.stringify(user));
      setUser(user)
      return true;
    } catch (error) {
      console.error("Error", error);
      return false;
    }
  };

  const setNewPassword = async (formData) => {
    try {
      await axios.post(`${API_URL}/user/setNewPassword`, {
        newPassword: formData.password
      });

      return true;
    } catch (error) {

      console.error("Error", error);
      return false;
    }
  };

  const checkEmail = async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/user/checkEmail`, {
        email: formData.email,
      });

      return response.data.data;
    } catch (error) {
      console.error("Error", error);
    }
  };

  const resetPassword = async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/user/resetPassword`, {
        email: formData.email,
        password: formData.password,
      });

      return response.data.success;
    } catch (error) {
      console.error("Error", error);
    }
  };

  const value = {
    user,
    setUser,
    login,
    signUp,
    checkEmail,
    resetPassword,
    changeUserInfo,
    setNewPassword
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
