import React, {useState} from 'react';
import styles from './header.module.scss'
import logoImage from './../../../assets/images/icons/logo.svg';
import {Link} from "react-router-dom";
import {Footer} from "../../../shared/components/footer/footer";
import AvatarStub from '../../images/icons/avatar-stub.svg'
import { useContent } from 'shared/services/ContentContext';
import {useAuth} from "../../../shared/services/AuthContext";
import {API_URL} from "../../../config";

const Header = () => {
    const [isBurgerActive, setIsBurgerActive] = useState(false);
    const { contentGroups } = useContent()
    const { user } = useAuth();
    let userPhoto;

    if (user.imagePath) {
        userPhoto = `${API_URL}/${user.imagePath}`;
    }

    const toggleClass = () => {
        setIsBurgerActive(!isBurgerActive);
    };

    return (
        <header className={`container ${styles.header} ${isBurgerActive ? styles.header_active : ''}`}>
            <Link to="/platform" className={styles.logo}>
                <img className={styles.logo__img} src={logoImage} alt="logo"/>
            </Link>
            <div className={styles.header__right}>
                <div className={`${styles.menuButton} ${isBurgerActive ? `${styles.active}` : ''}`}
                     onClick={toggleClass}>
                    <div className={styles.menuButtonBurger}/>
                </div>
                <div className={`${styles.navMobile} ${isBurgerActive ? `${styles.active}` : ''}`}>
                    <div className={styles.navLinks}>
                        <Link to="/platform" className={`${styles.navLinks__item} raleway-24-regular`}
                              onClick={() => toggleClass()}
                        >Головна</Link>
                        {contentGroups?.map((navMenuItem) => {
                                return (
                                    <Link key={navMenuItem.id} to={`/platform/content-group/${navMenuItem.id}`} className={`${styles.navLinks__item} raleway-24-regular`}
                                          onClick={() => {
                                              toggleClass();
                                          }}
                                    >{navMenuItem.name}</Link>
                                )
                            })
                        }
                        <Link to="/platform/settings" className={`${styles.navLinks__item} raleway-24-regular`}
                              onClick={() => {
                                  toggleClass();
                              }}
                        >Налаштування</Link>
                    </div>
                    <div className={styles.footer}>
                        <Footer/>
                    </div>
                </div>
                <Link className={`${styles.avatarStub}`} to="/platform/settings">
                    <img className={`${styles.avatarStub__img}`} src={userPhoto || AvatarStub} alt="avatarStub"/>
                </Link>
            </div>
        </header>
    );
};

export default Header;
