import styles from './notFoundPage.module.scss'
import notFoundSectionImg from './../../../assets/images/background/not-found.svg'
import {useNavigate} from "react-router-dom";

export const NotFoundPage = () => {
    const navigate = useNavigate();
    const scrollToSection = (sectionId) => {
        if (window.location.pathname !== '/landing') {
            navigate('/landing');
        }
        setTimeout(() => {
            const section = document.querySelector(sectionId);
            if (section) {
                section.scrollIntoView({behavior: 'smooth'});
            }
        }, 100);
    };

    return (
        <div className={styles.notFound}>
            <img className={styles.img} src={notFoundSectionImg} alt="notFoundSectionImg"/>
            <p className={`${styles.text} raleway-16-regular`}>
                Сторінка пішла на тренування, їй потрібно дати трішки часу. Скоро вона повернеться, а тим часом, ти
                також можеш виконати невеличку руханку, тисни на кнопку нижче.
            </p>
            <button className="btn-brown" onClick={() => scrollToSection("#exercisesVideo")}>Зарядка</button>
        </div>
    )
}
