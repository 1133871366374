import stylesUserJourney from "./userJourney.module.scss";
import React, { useState } from "react";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useAuth } from 'shared/services/AuthContext';
import { useNavigate } from 'react-router-dom';

export const ResetPasswordPage = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const { checkEmail } = useAuth();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const checkEmailStatus = await checkEmail(formData);
    if (checkEmailStatus.isAvailable) {
        navigate(`/landing/new-password?email=${formData.email}`)
    }
  };
  const goBack = useNavigation();

  return (
    <div className={`${stylesUserJourney.userJourney} container`}>
      <div onClick={goBack} className={stylesUserJourney.userJourney__back}>
        Повернутись
      </div>
      <div className={stylesUserJourney.userJourney__content}>
        <div className={stylesUserJourney.infoText}>
          <h2
            className={`${stylesUserJourney.infoText__h2} montserrat-36-black`}
          >
            Відновити пароль
          </h2>
          <p className={`${stylesUserJourney.infoText__p} raleway-16-regular`}>
            Щоб відновити пароль, введи твій email
          </p>
        </div>
        <form
          className={`${stylesUserJourney.formContainer} form`}
          onSubmit={handleSubmit}
        >
          <input
            className={"input"}
            type="email"
            name="email"
            placeholder="Email*"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <button
            className={`${stylesUserJourney.formContainer__btn} btn-brown`}
            type="submit"
          >
            Відправити
          </button>
        </form>
      </div>
    </div>
  );
};
