import './App.scss';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import PortalComponent from "./platform/platform";
import Landing from "./landing/landing";
import './assets/styles/colors.scss';
import './assets/styles/buttons.scss';
import './assets/styles/inputs.scss';
import './assets/styles/fonts.scss';
import './assets/styles/global.scss';
import './assets/styles/form.scss';
import { useAuth } from 'shared/services/AuthContext';

function App() {
    const { user } = useAuth();

    return (
        <Router>
            <Routes>
                <Route
                    path="/platform/*"
                    element={user ? <PortalComponent/> : <Navigate to="/landing" replace/>}
                />
                <Route
                    path="/landing/*"
                    element={!user ? <Landing/> : <Navigate to="/platform" replace/>}
                />
                <Route
                    path="*"
                    element={user ? <Navigate to="/platform" replace/> : <Navigate to="/landing" replace/>}
                />
            </Routes>
        </Router>
    );
}

export default App;
