import React, { useState } from 'react';
import PlayIcon from '../../images/icons/play_icon.svg'

const VimeoPlayerEmbed = ({ id, thumbnail }) => {
    const [showVideo, setShowVideo] = useState(false);

    const handleClick = () => {
        setShowVideo(true);
    };

    const htmlString = `<div style="padding:60% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/${id}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Test for website"></iframe></div>`;
    return (
        <div>
            {!showVideo ? (
                <div onClick={handleClick} style={{
                    position: 'relative',
                    cursor: 'pointer',
                    width: '100%',
                    height: '100%',
                    borderRadius: '16px',
                    overflow: 'hidden'
                }}>
                    <img
                        src={thumbnail}
                        alt="Video Thumbnail"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                    <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
                        <img
                            src={PlayIcon}
                            style={{
                                width: '54px',
                                height: '54px',
                            }}
                            alt="Play Icon"
                        />
                    </div>
                </div>
            ) : (
                <div dangerouslySetInnerHTML={{__html: htmlString}}/>
            )}
        </div>
    );
};

export default VimeoPlayerEmbed;
