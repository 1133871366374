import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./tariffsSlider.module.scss";
import {buyTariffs, createSignature, useTariffs} from "shared/services/TariffContext";
import { useNavigate } from 'react-router-dom';

import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import {useAuth} from "../../../../../shared/services/AuthContext";
import WayForPayForm from "../../../../../shared/components/wayForPayForm/wayForPayForm";

export const TariffsSlider = () => {
  const sliderRef = useRef(null);
  const [paymentData, setPaymentData] = useState(null);
  const [selectedTariffIndex, setSelectedTariffIndex] = useState(0);
  const { allTariffs, getAvailableTariff, availableTariffs } = useTariffs();
  const { user } = useAuth();
  let tariffsToShow = availableTariffs?.length ? availableTariffs : allTariffs;
  let navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      getAvailableTariff();
    }

  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handleSlideTo = (index) => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideTo(index);
  };

  const handleSlideChange = (swiper) => {
    setSelectedTariffIndex(swiper.activeIndex);
  };

  const buyTariff = async (selectedTariffId) => {
    if (user) {
      try {
        const orderReferences = await buyTariffs(user, [selectedTariffId]); // Передаємо масив з одним тарифом
        const signatureResult = await createSignature(user, orderReferences);
        setPaymentData(signatureResult);
      } catch (error) {
        console.error("There was an error!", error);
      }
    } else {
      navigate(`/landing/payment-processing?tariffId=${allTariffs[selectedTariffIndex].id}`);
      window.scrollTo(0, 0);
    }
  }

  return (
    <div className={styles.tariffsSliderContainer}>
      <div className={styles.tariffsSwitch}>
        {(tariffsToShow || []).map((tariff, index) => (
            <div
              key={tariff.id}
              className={`${styles.tariffsSwitch__item} ${
                index === selectedTariffIndex
                  ? styles.tariffsSwitch__item_active
                  : undefined
              } raleway-16-regular`}
              onClick={() => handleSlideTo(index)}
            >
              {tariff.title}
            </div>
          ))}
      </div>
      <div className={styles.tariffsSlider}>
        <Swiper
          ref={sliderRef}
          className={styles.swiper}
          pagination={{
            clickable: true,
          }}
          spaceBetween={30}
          slidesPerView={1}
          onSlideChange={handleSlideChange}
        >
          {(tariffsToShow || []).map((tariff, index) => (
              <SwiperSlide key={index} className={styles.swiper__item}>
                <div className={styles.shadow_left} />
                <div className={styles.shadow_right} />
                <div className={styles.content}>
                  <h3 className={`${styles.content__h3} montserrat-36-black`}>
                    {tariff.title}
                  </h3>
                  <div className={styles.content__description}>
                    <h4 className={`${styles.questionTitle} raleway-16-bold`}>
                      Що входить у тариф:
                    </h4>
                    {tariff.includedInTariff.map((item, itemIndex) => (
                      <p
                        key={itemIndex}
                        className={`${styles.answerText} raleway-16-regular`}
                      >
                        {item}
                      </p>
                    ))}
                    <h4 className={`${styles.questionTitle} raleway-16-bold`}>
                      Для чого вам такий тип тренувань?
                    </h4>
                    {tariff.reasonsToBuy.map((item, itemIndex) => (
                      <p
                        key={itemIndex}
                        className={`${styles.answerText} raleway-16-regular`}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                  <div className={styles.content__priceContainer}>
                    <span
                      className={`${styles.content__priceContainer__item} ${
                        tariff.discount
                          ? styles.content__priceContainer__item_oldPrice
                          : ""
                      } montserrat-36-black`}
                    >
                      {tariff.price} ГРН
                    </span>
                    {tariff.discount && (
                      <span
                        className={`${styles.content__priceContainer__item} montserrat-36-black`}
                      >
                        {tariff.discount} ГРН
                      </span>
                    )}
                  </div>
                  <p
                    className={`${styles.content__accessAllowedDays} raleway-24-regular`}
                  >
                    ДОСТУП {tariff.durationDays} ДНІВ
                  </p>
                  <button className={`${styles.content__btn} btn-brown`} onClick={() => buyTariff(tariff.id)}>
                    Оплатити
                  </button>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className={styles.prevArrow} onClick={handlePrev} />
        <div className={styles.nextArrow} onClick={handleNext} />
      </div>
      {paymentData && <WayForPayForm data={paymentData} />}
    </div>
  );
};
