import stylesUserJourney from "./userJourney.module.scss";
import React from "react";
import { Link } from "react-router-dom";
import resetPasswordSuccessIcon from "../../images/background/reset-password-success-bg.svg";

export const ResetPasswordSuccessPage = () => {
  return (
    <div className={`${stylesUserJourney.resetPasswordSuccess} container`}>
      <img
        className={`${stylesUserJourney.resetPasswordSuccess__img}`}
        src={resetPasswordSuccessIcon}
        alt="resetPasswordSuccess"
      />
      <h2
        className={`${stylesUserJourney.resetPasswordSuccess__h2} raleway-24-bold`}
      >
        Пароль успішно змінено!
      </h2>
      <p
        className={`${stylesUserJourney.resetPasswordSuccess__p} raleway-16-regular`}
      >
        Пароль був успішно змінений. Тепер ти можеш увійти на сайт, та розпочати
        свій шлях.
      </p>
      <Link to="/landing/login">
        <button
          className={`${stylesUserJourney.resetPasswordSuccess__btn} btn-brown`}
        >
          Вхід
        </button>
      </Link>
    </div>
  );
};
