import React, {useState} from 'react';
import styles from './feedbackForm.module.scss';
import emailjs from 'emailjs-com';

function FeedbackForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        comment: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            from_name: formData.name,
            message: formData.comment,
            reply_to: formData.email,
            subject: formData.subject
        };

        emailjs.send('service_vigt603', 'template_x9mivn5', templateParams, 'cMPE-wy_mKt_n_IEc')
            .then((result) => {
                console.log('Лист успішно надіслано:', result.text);

                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    comment: ''
                });
                setIsSubmitted(true);
            }, (error) => {
                console.error('Помилка під час надсилання листа:', error.text);
            });
    };

    return (
        <div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <input
                    className={'input'}
                    type="text"
                    name="name"
                    placeholder="Твоє ім'я*"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                />
                <input
                    className={'input'}
                    type="email"
                    name="email"
                    placeholder="Email*"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                />
                <input
                    className={'input'}
                    type="text"
                    name="subject"
                    placeholder="Тема"
                    value={formData.subject}
                    onChange={handleInputChange}
                />
                <input
                    className={'input'}
                    type="text"
                    name="comment"
                    placeholder="Коментар"
                    value={formData.comment}
                    onChange={handleInputChange}
                />
                <button className={`${styles.btn} btn-brown`} type="submit">Надіслати</button>
            </form>

            {isSubmitted && <p className={`${styles.submitted } raleway-16-regular`}>Лист успішно відправлено!</p>}
        </div>
    );
}

export default FeedbackForm;
