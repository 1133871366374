import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const modalRootEl = document.getElementById('modal-root');

const Modal = ({ children }) => {
    const el = document.createElement('div');

    useEffect(() => {
        modalRootEl.appendChild(el);
        return () => {
            modalRootEl.removeChild(el);
        };
    }, [el]);

    return ReactDOM.createPortal(
        children,
        el
    );
};

export default Modal;
