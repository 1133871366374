import stylesUserJourney from './userJourney.module.scss'
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {useNavigation} from "../../../shared/hooks/useNavigation";
import { useAuth } from "../../../shared/services/AuthContext";


export const LoginPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',

    });
    const { login } = useAuth();

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        login(formData);
    };

    const goBack = useNavigation();

    return (
        <div className={`${stylesUserJourney.userJourney} container`}>
            <div onClick={goBack} className={stylesUserJourney.userJourney__back}>Повернутись</div>
            <div className={stylesUserJourney.userJourney__content}>
                <div className={stylesUserJourney.infoText}>
                    <h2 className={`${stylesUserJourney.infoText__h2} montserrat-36-black`}>Увійти до особистого
                        кабінету</h2>
                    <p className={`${stylesUserJourney.infoText__p} raleway-16-regular`}>
                        Заповни форму, щоб авторизуватись або <Link
                        className={`${stylesUserJourney.link} raleway-16-bold`}
                        to="/landing/payment-processing">зареєструйся</Link>, якщо ти ще не в команді
                    </p>
                </div>
                <form className={`${stylesUserJourney.formContainer} form`} onSubmit={handleSubmit}>
                    <input
                        className={'input'}
                        type="email"
                        name="email"
                        autoComplete="email"
                        placeholder="Email*"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        className={'input'}
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        placeholder="Пароль*"
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                    <Link className={`${stylesUserJourney.link} ${stylesUserJourney.link__forgotPassword} raleway-16-regular`} to="/landing/reset-password">
                        Забули пароль?
                    </Link>
                    <button className={`${stylesUserJourney.formContainer__btn} btn-brown`} type="submit">
                        Увійти
                    </button>
                </form>
            </div>
        </div>
    )
}
