import React, { useCallback, useRef, useState } from "react";
import styles from "./currentTariffSlider.module.scss";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import {buyTariffs, createSignature, useTariffs} from "../../../shared/services/TariffContext";
import Checkbox from "../../../shared/components/checkbox/checkbox";
import { ModalCancelTariff } from "../modalCancelTariff/modalCancelTariff";
import { ModalRestoreTariff } from "../modalRestoreTariff/modalRestoreTariff";
import { ModalSuccessful } from "../modalSuccessful/modalSuccessful";
import {useAuth} from "../../../shared/services/AuthContext";
import WayForPayForm from "../../../shared/components/wayForPayForm/wayForPayForm";

export const CurrentTariffSlider = () => {
  const { user } = useAuth();
  const [paymentData, setPaymentData] = useState(null);
  const sliderRef = useRef(null);
  const [selectedTariff, setSelectedTariff] = useState("first");
  const [isModalCancelTariffOpen, setIsModalCancelTariffOpen] = useState(false);
  const [isModalRestoreTariffOpen, setIsModalRestoreTariffOpen] =
    useState(false);
  const [isModalSuccessfulOpen, setIsModalSuccessfulOpen] = useState(false);
  const { connectedTariffs, cancelAutoPayment, resumeAutoPayment } = useTariffs();
  const [currentOrderReference, setCurrentOrderReference] = useState(false);

  const handlePrev = useCallback(() => {
    setSelectedTariff(null);
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    if (sliderRef?.current?.swiper.activeIndex === 0) {
      setSelectedTariff("first");
    }
  }, []);

  const handleNext = useCallback(() => {
    setSelectedTariff(null);
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    if (
      sliderRef?.current?.swiper.activeIndex ===
      connectedTariffs.length - 1
    ) {
      setSelectedTariff("last");
    }
  }, [connectedTariffs]);

  const resumeTariffClick = (orderReference) => {
    setCurrentOrderReference(orderReference)
    setIsModalRestoreTariffOpen(true)
  }

  const cancelTariffClick = (orderReference) => {
    setCurrentOrderReference(orderReference)
    setIsModalCancelTariffOpen(true)
  }

  const paymentTariff = async (tariff) => {
    const orderReferences = await buyTariffs(user, tariff.id);
    const signatureResult = await createSignature(user, orderReferences);
    setPaymentData(signatureResult);
  };

  return (
    <div className={styles.tariffsSliderContainer}>
      <div className={styles.tariffsSlider}>
        <div className={styles.top}>
          <h2 className={`${styles.title} raleway-24-bold`}>Поточний тариф</h2>
          {connectedTariffs && connectedTariffs.length > 1 && (
            <>
              <div
                className={`${styles.prevArrow} ${
                  selectedTariff === "first" ? styles.inactive : ""
                }`}
                onClick={handlePrev}
              />
              <div
                className={`${styles.nextArrow} ${
                  selectedTariff === "last" ? styles.inactive : ""
                }`}
                onClick={handleNext}
              />
            </>
          )}
        </div>
        <Swiper
          ref={sliderRef}
          className={styles.swiper}
          pagination={{
            clickable: true,
          }}
          spaceBetween={30}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            if (swiper.activeIndex === 0) {
              setSelectedTariff("first");
            } else if (swiper.activeIndex === connectedTariffs.length - 1) {
              setSelectedTariff("last");
            } else {
              setSelectedTariff(null);
            }
          }}
        >
          {connectedTariffs &&
            connectedTariffs.map((tariff) => (
              <SwiperSlide key={tariff.id} className={styles.swiper__item}>
                <div className={styles.currentTariff}>
                  <h3
                    className={`${styles.currentTariff__title} raleway-16-bold`}
                  >
                    ЗАРЯДКА
                  </h3>
                  {!tariff.userPurchase.autoPayment && (
                    <button
                      className={`${styles.currentTariff__cancelTariff} btn-transparent`}
                      onClick={() => resumeTariffClick(tariff.userPurchase.orderReference)}
                    >
                      Відновити тариф
                    </button>
                  )}
                  {tariff.userPurchase.autoPayment && (
                    <button
                      className={`${styles.currentTariff__cancelTariff} btn-transparent`}
                      onClick={() => cancelTariffClick(tariff.userPurchase.orderReference)}
                    >
                      Скасувати тариф
                    </button>
                  )}
                  <h4 className={`${styles.questionTitle} raleway-16-bold`}>
                    Що входить у тариф:
                  </h4>
                  {tariff.includedInTariff.map((item) => (
                    <p className={`${styles.answerText} raleway-16-regular`}>
                      {item}
                    </p>
                  ))}
                  {!tariff.active && (
                    <div className={styles.currentTariff__cancelled}>
                      <h2
                        className={`${styles.currentTariff__cancelled__title} raleway-16-bold`}
                      >
                        Тариф скасовано
                      </h2>
                      <h3
                        className={`${styles.currentTariff__cancelled__time} raleway-16-bold`}
                      >
                        Термін завершиться 23.10.2023
                      </h3>
                    </div>
                  )}
                  {tariff.active && (
                    <div className={styles.currentTariff__paymentDetails}>
                      <div className={styles.block_one}>
                        <h4 className={`${styles.title} raleway-16-bold`}>
                          Оплата:
                        </h4>
                        <p className={`${styles.price} raleway-16-bold`}>
                          {tariff.price}грн
                        </p>
                      </div>
                      <div className={styles.block_two}>
                        <h3 className={`${styles.time} raleway-16-bold`}>
                          Термін завершиться 23.10.2023
                        </h3>
                        <Checkbox
                          className={styles.checkbox}
                          label="Автоплатіж щомісяця"
                          defaultChecked={tariff.userPurchase.autoPayment}
                          onChange={(value) => {
                            if (value) {
                              resumeTariffClick(tariff.userPurchase.orderReference)
                            } else {
                              cancelTariffClick(tariff.userPurchase.orderReference)
                            }
                          }}
                        />
                      </div>
                      {/*<div className={styles.block_three}>*/}
                      {/*  <button className={`${styles.btn} btn-brown`} onClick={() => paymentTariff(tariff)}>*/}
                      {/*    Оплатити*/}
                      {/*  </button>*/}
                      {/*</div>*/}
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      {isModalCancelTariffOpen && (
        <ModalCancelTariff
            onBtnClickNo={() => setIsModalCancelTariffOpen(false)}
            onBtnClickYes={() => {
              cancelAutoPayment(currentOrderReference)
              setIsModalCancelTariffOpen(false)
            }}
        />
      )}
      {isModalRestoreTariffOpen && (
        <ModalRestoreTariff
            onBtnClickNo={() => setIsModalRestoreTariffOpen(false)}
            onBtnClickYes={() => {
              resumeAutoPayment(currentOrderReference)
              setIsModalRestoreTariffOpen(false)
            }}
        />
      )}
      {isModalSuccessfulOpen && (
        <ModalSuccessful
          description="Твій тариф був успішно скасований, і доступ до його можливостей збережеться до закінчення терміну дії."
          onBtnClick={() => setIsModalSuccessfulOpen(false)}
        />
      )}
      {paymentData && <WayForPayForm data={paymentData} />}
    </div>
  );
};
