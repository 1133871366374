import Modal from "../modal/modal";
import React from "react";
import styles from './modalRestoreTariff.module.scss'

export const ModalRestoreTariff = ({onBtnClickNo, onBtnClickYes}) => {
    return (
        <Modal>
            <div className="modal">
                <div className={styles.modalContent}>
                    <h2 className={`${styles.title} raleway-24-bold`}>Ти дійсно хочеш відновити тариф?</h2>
                    <p className={`${styles.description} raleway-16-regular`}>При поновленні тарифу ти отримаєш доступ
                        до матеріалу ще на один місяць. Також можна налаштувати автоматичне продовження передоплати.</p>
                    <div>
                        <button className={`${styles.btn} btn-transparent`} onClick={onBtnClickNo}>Ні</button>
                        <button className={`${styles.btn} btn-brown`} onClick={onBtnClickYes}>Так, відновити</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
