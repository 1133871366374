import styles from './tableOfPayments.module.scss'
import {useEffect, useState} from "react";
import {useTariffs} from "../../../shared/services/TariffContext";

export const TableOfPayments = () => {
    const [paymentHistory, setPaymentHistory] = useState([]);
    const { getPaymentHistory } = useTariffs();



    useEffect( () => {
        const fetchPaymentHistory = async () => {
            try {
                const payment = await getPaymentHistory();
                setPaymentHistory(payment);
            } catch (error) {
                console.error('Failed to fetch payment history:', error);
            }
        };

        fetchPaymentHistory();
    }, []);

    function getStatusClassName(status) {
        const statusMap = {
            'success': 'td__status_green',
            'failed': 'td__status_red',
        };
        return statusMap[status];
    }

    function getStatusName(status) {
        const statusMap = {
            'success': 'Успішно',
            'failed': 'Помилка',
        };
        return statusMap[status] || status;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('uk-UA', options);
    }

    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead className={styles.thead}>
                <tr>
                    <th className={`${styles.th} raleway-12-medium`}>№</th>
                    <th className={`${styles.th} raleway-12-medium`}>Дата платежу</th>
                    <th className={`${styles.th} raleway-12-medium`}>Опис</th>
                    <th className={`${styles.th} raleway-12-medium`}>Сума</th>
                    <th className={`${styles.th} raleway-12-medium`}>Статус</th>
                </tr>
                </thead>
                <tbody>
                {paymentHistory?.length > 0 && paymentHistory?.map((item, index) => (
                    <tr className={styles.tr} key={index}>
                        <td className={`${styles.td} raleway-12-medium`}>{index + 1}</td>
                        <td className={`${styles.td} raleway-12-medium`}>{formatDate(item.paymentDate)}</td>
                        <td className={`${styles.td} raleway-12-medium`}>{`Тариф ${item.tariffName}`}</td>
                        <td className={`${styles.td} raleway-12-medium`}>{`${item.amount}грн`}</td>
                        <td className={`${styles.td} ${styles.td__status} ${styles[getStatusClassName(item.status)]} raleway-12-medium`}>{getStatusName(item.status)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}
