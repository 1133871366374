import { MY_DOMAIN } from './../../../config';
import React, { useEffect } from 'react';

const WayForPayForm = ({ data }) => {
  useEffect(() => {
    document.getElementById('wayforpay-form').submit();
  }, []);

  return (
    <form id="wayforpay-form" action="https://secure.wayforpay.com/pay/" method="POST">
      <input type="hidden" name="merchantAccount" value={data.merchantAccount} />
      <input type="hidden" name="merchantDomainName" value={data.merchantDomainName} />
      <input type="hidden" name="orderReference" value={data.orderReference} />
      <input type="hidden" name="orderDate" value={data.orderDate} />
      <input type="hidden" name="amount" value={data.amount} />
      <input type="hidden" name="currency" value={data.currency} />
      {data.productName.map((name, index) => (
        <React.Fragment key={index}>
          <input type="hidden" name="productName[]" value={name} />
          <input type="hidden" name="productCount[]" value={data.productCount[index]} />
          <input type="hidden" name="productPrice[]" value={data.productPrice[index]} />
        </React.Fragment>
      ))}
      <input type="hidden" name="merchantSignature" value={data.merchantSignature} />
      <input type="hidden" name="serviceUrl" value={data.serviceUrl} />
      <input type="hidden" name="returnUrl" value={`${MY_DOMAIN}/platform/settings?tab=TARIFF&status=success`} />
      <input style={{ display: 'none' }} type="submit" value="Оплатити зараз" />
    </form>
  );
};

export default WayForPayForm;
