import React from "react";
import styles from './radioButton.module.scss';

const RadioButton = ({ label, name, value, checked, onChange, isLabelOpaque = false }) => {
    const labelStyle = isLabelOpaque ? { opacity: '40%' } : {};

    return (
        <label className={`${styles.radioContainer} raleway-16-regular`}>
            <span style={labelStyle}>{label}</span>
            <input
                type="radio"
                className={styles.radio}
                name={name}
                value={value}
                checked={checked}
                onChange={() => onChange(value)}
            />
            <span className={styles.checkmark} />
        </label>
    );
};

export default RadioButton;
