import stylesUserJourney from "./userJourney.module.scss";
import React, { useState, useEffect } from "react";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useAuth } from "shared/services/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";

export const NewPasswordPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const { resetPassword } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    if (email) {
      setFormData((prevState) => ({
        ...prevState,
        email: email,
      }));
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password === formData.confirmPassword) {
      const status = await resetPassword(formData);
      if (status) {
        navigate('/landing/password-changed')
      }
    }
  };

  const goBack = useNavigation();

  return (
    <div className={`${stylesUserJourney.userJourney} container`}>
      <div onClick={goBack} className={stylesUserJourney.userJourney__back}>
        Повернутись
      </div>
      <div className={stylesUserJourney.userJourney__content}>
        <div className={stylesUserJourney.infoText}>
          <h2
            className={`${stylesUserJourney.infoText__h2} montserrat-36-black`}
          >
            Cтворити новий пароль
          </h2>
          <p className={`${stylesUserJourney.infoText__p} raleway-16-regular`}>
            Щоб створити новий пароль, заповни наступну форму:
          </p>
        </div>
        <form
          className={`${stylesUserJourney.formContainer} form`}
          onSubmit={handleSubmit}
        >
          <input
            className={"input"}
            type="password"
            name="password"
            autoComplete="new-password"
            placeholder="Новий пароль*"
            value={formData.password}
            onChange={handleInputChange}
          />
          <input
            className={"input"}
            type="password"
            name="confirmPassword"
            autoComplete="new-password"
            placeholder="Підтвердити пароль*"
            value={formData.confirmPassword}
            onChange={handleInputChange}
          />
          <button
            className={`${stylesUserJourney.formContainer__btn} btn-brown`}
            type="submit"
          >
            Зберегти
          </button>
        </form>
      </div>
    </div>
  );
};
